
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";

interface IDropdownData {
  id: string;
  name: string;
}

export default defineComponent({
  name: "request-access",
  components: {},
  data() {
    return {
      userEmail: ref<string | null>(null),
      selectedDivision: ref<string | null>(null),
      selectedAccess: ref<string | null>(null),
      descriptionInput: ref<string>(""),
      divisions: ref<Array<IDropdownData>>([]),
      access: ref<Array<IDropdownData>>([]),
      clientId: process.env.VUE_APP_G_CLIENT_ID,
    };
  },
  methods: {
    divisionOption() {
      return this.divisions?.map((el) => {
        // return ["USR", "ORD","PAYMENT"]?.map((el) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
    },
    accessOption() {
      return this.access?.map((el) => {
        // return ["development prod", "development dev"].map((el) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
    },
    handleClick() {
      Promise.resolve(window.gapi.auth2.getAuthInstance().signIn()).then(
        (reqs) => {
          this.userEmail = reqs.getBasicProfile().getEmail();
        }
      );
    },
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
    async onCreateRequest() {
      try {
        const payload = {
          email: this.userEmail,
          division: Number(this.selectedDivision),
          requested_access: Number(this.selectedAccess),
          description: this.descriptionInput,
        };
        await ApiService.vueInstance.axios.post("request/v1/access", payload);
        ElMessage({
          showClose: true,
          message: "Your Access has been requested.",
          type: "success",
        });
        this.selectedAccess = null;
        this.selectedDivision = null;
        this.descriptionInput = "";
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getRequestType() {
      if (!this.selectedDivision) return false;
      try {
        const requestTypeList = await ApiService.vueInstance.axios(
          `request/v1/type/list?division_id=${Number(this.selectedDivision)}`
        );
        this.access.splice(0, this.access.length, ...requestTypeList.data.data);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getDivisionList() {
      try {
        const employeeDivisionList = await ApiService.vueInstance.axios(
          "employee/v1/division/list"
        );
        this.divisions.splice(
          0,
          this.divisions.length,
          ...employeeDivisionList.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  computed: {
    sendable: function () {
      let isSendable = true;
      if (this.userEmail && this.selectedDivision && this.selectedAccess)
        isSendable = false;
      return isSendable;
    },
  },
  mounted() {
    this.getDivisionList();
    if (!window.gapi) {
      throw new Error(
        '"https://apis.google.com/js/api:client.js" needs to be included as a <script>.'
      );
    }

    if (!this.clientId) {
      throw new Error("Client Id must be specified.");
    }

    window.gapi.load("auth2", () => {
      const auth2 = window.gapi.auth2.init({
        client_id: this.clientId,
        ux_mode: "popup",
      });

      auth2.attachClickHandler(
        this.$refs.signinBtn,
        {},
        (googleUser) => {
          this.$emit("success", googleUser);
        },
        (error) => {
          this.$emit("error", error);
          this.$emit("failure", error); // an alias
        }
      );
    });
  },
});
